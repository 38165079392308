<template>
	<div class="background-white w-100 mb-60 py-60 px-80 shadow-lg rounded">
		<h1 class="mb-30">Upplýsingar um brúkaran</h1>

		<div class="grid grid-2 grid-gap-20">
			<InputGroup v-model="form.firstName" @blur="markInputAsDirty('firstName')" :error="$v.form.firstName.$invalid && $v.form.firstName.$dirty" :error-message="'Vinaliga skriva fornavnið'" label="Fornavn" />

			<InputGroup v-model="form.lastName" @blur="markInputAsDirty('lastName')" :error="$v.form.lastName.$invalid && $v.form.lastName.$dirty" :error-message="'Vinaliga skriva eftirnavnið'" label="Eftirnavn" />
		</div>

		<InputGroup v-model="form.email" label="Teldubústaður" @blur="markInputAsDirty('email')" :error="$v.form.email.$invalid && $v.form.email.$dirty" :error-message="'Vinaliga skriva ein teldubústað'" />

		<div class="grid grid-2 grid-gap-20">
			<InputGroup v-model="form.password" label="Loyniorð" @blur="markInputAsDirty('password')" :error="$v.form.password.$invalid && $v.form.password.$dirty" :error-message="'Vinaliga skriva eitt loyniorð, sum er minst 8 tekin'" />

			<InputGroup v-model="form.password_confirm" label="Vátta loyniorð" @blur="markInputAsDirty('password_confirm')" :error="$v.form.password_confirm.$invalid && $v.form.password_confirm.$dirty" :error-message="'Loyniorðið er ikki eins'" />
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators';
import InputGroup from '@/components/InputGroup.vue';

export default {
	name: 'User',

	props: {
		value: Object,
	},

	components: {
		InputGroup,
	},

	data() {
		return {
			form: {
				firstName: '',
				lastName: '',
				email: '',
				password: '',
				password_confirm: '',
			},
		};
	},

	mounted() {
		this.form.firstName = this.value.firstName;
		this.form.lastName = this.value.lastName;
		this.form.email = this.value.email;
	},

	watch: {
		value: {
			handler(value) {
				this.form.firstName = value.firstName;
				this.form.lastName = value.lastName;
				this.form.email = value.email;
			},
		},

		form: {
			handler(value) {
				this.$emit('input', value);
			},

			deep: true,
		},
	},

	validations: {
		form: {
			firstName: {
				required,
				minLength: minLength(3),
			},

			lastName: {
				required,
				minLength: minLength(3),
			},

			email: {
				required,
				email,
			},

			password: {
				required,
				minLength: minLength(8),
			},

			password_confirm: {
				required,
				sameAsPassword: sameAs('password'),
			},
		},
	},

	methods: {
		markInputAsDirty(inputName) {
			this.$v.form[inputName].$touch();
		},

		saveUser() {
			axios
				.post('/users', this.form)
				.then(() => {
					this.$router.push({ name: 'Users' });
				})
				.catch((error) => {
					console.log('Error saving user', error);
				});
		},
	},
};
</script>
